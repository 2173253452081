import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { rem } from "polished"
import { Base64 } from "js-base64"

const offset = 1.2
const chunk = 6.9

const DotsContainer = styled.div`
  ${(props) =>
    props.dots &&
    css`
      &::before {
        height: 90%;
        width: 100%;
        content: "";
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        padding-right: ${offset * 2}%;
        background-size: ${chunk}%;
        background-origin: content-box;
        background-image: url("data:image/svg+xml;base64,${(props) =>
          Base64.encode(`
          <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56">
            <circle
              cx="4"
              cy="4"
              r="4"
              fill="${props.dots.color}"
              fill-opacity="${props.dots.opacity || 1}"
            />
          </svg>
      `)}");

        @media ${(props) => props.theme.mediumDown} {
          background-size: ${chunk * 1.5}%;
          padding-right: ${offset * 2}%;
          margin-left: 50px;
          height: 100%;
        }

        @media ${(props) => props.theme.xsmallDown} {
          margin-left: 15px;
          background-size: ${chunk * 1.9}%;
        }
      }
    `}
`

const Container = styled.div`
  color: ${(props) => props.theme.colorWhite};
  background-color: ${(props) => props.theme.colorPurple};
  padding-top: ${rem(50)};
`

const InnerContainer = styled.div`
  max-width: ${rem(1680)};
  margin: 0 auto;
`

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 50%;

  @media ${(props) => props.theme.smallDown} {
    width: 100%;
    justify-content: center;
    padding-bottom: ${rem(50)};
  }

  img {
    max-height: ${rem(285)};
    margin-top: ${rem(30)};
    margin-right: ${rem(130)};

    @media ${(props) => props.theme.mediumDown} {
      margin-right: ${rem(65)};
    }

    @media ${(props) => props.theme.smallDown} {
      margin-right: 0;
      max-height: ${rem(155)};
    }
  }
`

const TextContainer = styled.div`
  width: 50%;
  padding-right: ${rem(50)};
  margin-left: ${rem(50)};
  margin-top: ${rem(180)};
  margin-bottom: ${rem(120)};

  @media ${(props) => props.theme.smallDown} {
    width: 100%;
    margin: 0;
    margin-bottom: ${rem(40)};
    padding: ${rem(50)};
  }

  @media ${(props) => props.theme.xsmallDown} {
    padding: ${rem(15)};
  }

  .styled-h4 {
    margin-top: ${rem(15)};

    @media ${(props) => props.theme.smallDown} {
      margin-top: 0;
    }
  }
`

const Info = styled.div`
  background-color: ${(props) => props.theme.colorPurple};
  position: relative;
  display: flex;

  @media ${(props) => props.theme.smallDown} {
    margin-top: ${rem(38)};
    flex-direction: column-reverse;
    align-items: center;
  }

  h1 {
    font-size: ${rem(32)};
    opacity: 1;

    @media ${(props) => props.theme.smallDown} {
      font-size: ${rem(28)};
    }
  }

  p {
    opacity: 0.6;
    font-size: ${rem(24)};
    margin-top: ${rem(10)};

    @media ${(props) => props.theme.smallDown} {
      font-size: ${rem(18)};
    }
  }
`

const HeroIllustration = ({ data: { title, description, illustration } }) => {
  return (
    <Container>
      <InnerContainer>
        <Info>
          <TextContainer>
            <h1
              className="styled-h4"
              dangerouslySetInnerHTML={{ __html: title }}
            />

            <div
              className="styled-h4"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </TextContainer>

          <ImageContainer>
            <DotsContainer dots={{ color: `#fff`, opacity: 0.2 }} />

            <img
              src={`/product-illustrations/${illustration}.svg`}
              alt={title}
              role="presentation"
            />
          </ImageContainer>
        </Info>
      </InnerContainer>
    </Container>
  )
}

HeroIllustration.propTypes = {
  data: PropTypes.object.isRequired,
}

export default HeroIllustration
