import React, { useEffect, useState, useMemo, memo } from "react"
import { useCookies } from "react-cookie"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"
import { throttle } from "lodash"
import stringHash from "string-hash"

import Grid from "../dotgrid/grid"
import Row from "../dotgrid/row"
import Cell from "../dotgrid/cell"
import { StoreConsumer } from "../../store"

const Container = styled.div`
  width: 100%;
  position: absolute;
  z-index: 10;
  bottom: ${(props) => (props.inView ? 0 : `-100%`)};
  background-color: rgba(30, 0, 40, 0.7);
  transition: bottom ease-in-out 1.1s;
`

const CellClose = styled(Cell)`
  order: 10;
  text-align: right;
  padding-top: ${rem(10)};

  @media ${(props) => props.theme.mediumDown} {
    order: 0;
    padding-top: 0;
  }
`

const Close = styled.button`
  width: 1.5em;
  height: 1.5em;
  display: inline-block;
  position: relative;
  color: ${(props) => props.theme.colorWhite};

  &:hover,
  &:focus {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.5;
  }

  &::before,
  &::after {
    content: "";
    width: 100%;
    height: 2px;
    background-color: currentColor;
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`

const Title = styled.h2`
  color: ${(props) => props.theme.colorWhite};
  font-size: ${rem(28)};

  @media ${(props) => props.theme.mediumDown} {
    font-size: ${rem(22)};
  }
`

const Text = styled.p`
  color: ${(props) => props.theme.colorBlackShadows};
  font-size: ${rem(24)};
  margin-top: ${rem(25)};
  max-width: ${rem(620)};

  @media ${(props) => props.theme.mediumDown} {
    font-size: ${rem(18)};
  }
`

const Link = styled.a`
  display: inline-block;
  color: ${(props) => props.theme.colorWhite};
  font-size: ${rem(24)};
  border-bottom: 1px solid ${(props) => props.theme.colorWhite};
  margin-top: ${rem(28)};

  @media ${(props) => props.theme.mediumDown} {
    font-size: ${rem(18)};
  }
`

const HeroBanner = ({ data: { visible, title, text, link } }) => {
  const cookieName = `hero-banner`
  const [cookies, setCookie] = useCookies([cookieName])
  const [bannerInView, setBannerInView] = useState(false)
  const hash = useMemo(
    () => stringHash(title + text + JSON.stringify(link)),
    [title, text, link]
  )
  const hide = !visible || cookies[cookieName] == hash

  const closeClick = () => {
    setCookie(cookieName, hash)
  }

  useEffect(() => {
    if (typeof window === `undefined` || hide) return undefined

    const timeout = window.setTimeout(() => setBannerInView(true), 2000)

    const scrollHandler = throttle(() => {
      if (window.scrollY > 300) setBannerInView(false)
      else if (window.scrollY <= 300) setBannerInView(true)
    }, 200)

    window.addEventListener(`scroll`, scrollHandler)

    return () => {
      window.clearTimeout(timeout)
      window.removeEventListener(`scroll`, scrollHandler)
    }
  }, [])

  if (hide) return null

  return (
    <Container inView={bannerInView}>
      <Grid>
        <Row>
          <CellClose
            size={1}
            pushX={1}
            mq={{ mediumDown: { size: 23, pushX: 0, pullY: 1 } }}
          >
            <StoreConsumer>
              {({ translations }) => (
                <Close
                  onClick={closeClick}
                  title={translations[`hide_menu`]}
                  aria-label={translations[`hide_menu`]}
                />
              )}
            </StoreConsumer>
          </CellClose>

          <Cell size={21} mq={{ xsmallDown: { size: 23 } }}>
            <Title dangerouslySetInnerHTML={{ __html: title }} />

            <Text dangerouslySetInnerHTML={{ __html: text }} />

            <Link
              href={link.url}
              target={link.target}
              dangerouslySetInnerHTML={{ __html: link.title }}
            />
          </Cell>
        </Row>
      </Grid>
    </Container>
  )
}

HeroBanner.propTypes = {
  data: PropTypes.object.isRequired,
}

export default memo(HeroBanner)
