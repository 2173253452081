import React from "react"
import PropTypes from "prop-types"

import HeroColor from "./hero-color"
import HeroImage from "./hero-image"
import HeroIllustration from "./hero-illustration-alt"
import HeroLanding from "./hero-landing"
import SiteHeader from "../header"

const Hero = ({ data: { acf }, showHeader }) => {
  const { type } = acf

  // Check if Hero should be displayed. Discretely check for non
  // false values, to cover for the possibility of null, which
  // should temporarily default to true
  const showHero = acf.toggle !== false && acf.title

  const heroSwitch = () => {
    switch (type) {
      case `color`:
      default:
        return <HeroColor data={acf} />

      case `image`:
        return <HeroImage data={acf} />

      case `illustration`:
        return <HeroIllustration data={acf} />

      case `landing`:
        return <HeroLanding data={acf} />
    }
  }

  return (
    <>
      {showHeader && <SiteHeader />}

      {showHero && heroSwitch()}
    </>
  )
}

Hero.propTypes = {
  data: PropTypes.object.isRequired,
  showHeader: PropTypes.bool,
}

Hero.defaultProps = {
  showHeader: true,
}

export default Hero
