import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image/withIEPolyfill"
import styled from "styled-components"
import { rem } from "polished"

import Grid from "../dotgrid/grid"
import Row from "../dotgrid/row"
import Cell from "../dotgrid/cell"

const Container = styled.div`
  min-height: calc(65vh - 150px);
  display: flex;
  flex-direction: column;
  position: relative;
`

const Bg = styled.div.attrs({ ariaHidden: true })`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  overflow: hidden;
  background-color: ${(props) => props.theme.colorPurple};
`

const GridStyled = styled(Grid)`
  /* IE */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    min-height: 50vh;
  }
`

const RowStyled = styled(Row)`
  min-height: 100%;
  align-items: flex-end;
`

const CellStyled = styled(Cell)`
  padding-right: 3em;
  position: relative;
  overflow: hidden;

  @media ${(props) => props.theme.xsmallDown} {
    padding-right: 1.5em;
  }
`

const Mask = styled.div`
  overflow: hidden;
  width: 0;
  height: 0;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  will-change: width, height, top, left;
`

const Heading = styled.h1.attrs({ className: `styled-h2` })`
  padding: 0.3em 0;
  position: relative;
  z-index: 2;
  color: ${(props) => props.theme.colorWhite};

  > .-pre {
    display: block;
    opacity: 0.6;
  }
`

class HeroImage extends React.Component {
  to = null

  componentDidMount() {
    this.setMaskSize()
    this.to = window.setTimeout(this.setMaskSize, 500)

    window.addEventListener(`resize`, this.winResize, { passive: true })
  }

  componentWillUnmount() {
    window.removeEventListener(`resize`, this.winResize, { passive: true })
    window.clearTimeout(this.to)
  }

  render() {
    const {
      title,
      pretitle,
      backgroundImage,
      backgroundImageHalign,
      backgroundImageValign,
    } = this.props.data

    let singleBackgroundImage
    if (Array.isArray(backgroundImage)) {
      singleBackgroundImage =
        backgroundImage[
          Math.floor(Math.random() * Math.floor(backgroundImage.length))
        ]
    } else {
      singleBackgroundImage = backgroundImage
    }

    const imageMarkup =
      !singleBackgroundImage || !singleBackgroundImage.localFile ? null : (
        <Img
          fluid={singleBackgroundImage.localFile.childImageSharp.fluid}
          objectPosition={`
            ${backgroundImageHalign || `center`}
            ${backgroundImageValign || `center`}
          `}
          objectFit="cover"
          style={{
            width: `100%`,
            height: `100%`,
            display: `block`,
          }}
          alt={title}
        />
      )

    return (
      <Container ref={(n) => (this.container = n)}>
        <GridStyled dots={{ color: `#fff`, opacity: 0.4 }} fillHeight>
          <RowStyled>
            <CellStyled maxSize={19}>
              <Heading>
                {pretitle && (
                  <span
                    className="-pre"
                    dangerouslySetInnerHTML={{ __html: pretitle }}
                  />
                )}

                <span dangerouslySetInnerHTML={{ __html: title }} />
              </Heading>

              <Mask ref={(n) => (this.mask = n)}>{imageMarkup}</Mask>
            </CellStyled>
          </RowStyled>
        </GridStyled>

        <Bg ref={(n) => (this.bg = n)}>{imageMarkup}</Bg>
      </Container>
    )
  }

  setMaskSize = () => {
    const bgRect = this.bg.getBoundingClientRect()
    const containerRect = this.container.getBoundingClientRect()
    const maskParentRect = this.mask.parentNode.getBoundingClientRect()

    this.mask.style.width = rem(bgRect.width)
    this.mask.style.height = rem(bgRect.height)
    this.mask.style.top = rem(-(maskParentRect.top - containerRect.top))
    this.mask.style.left = rem(-maskParentRect.left)
  }

  winResize = () => {
    this.setMaskSize()
    window.clearTimeout(this.to)
    this.to = window.setTimeout(this.setMaskSize, 500)
  }
}

HeroImage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default HeroImage
