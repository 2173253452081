import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Grid from "../dotgrid/grid"
import Row from "../dotgrid/row"
import Cell from "../dotgrid/cell"

const Container = styled.div`
  min-height: calc(50vh - 150px);
  position: relative;
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.colorWhite};
  background-color: ${(props) => props.color || props.theme.colorPurple};

  @media ${(props) => props.theme.mediumDown} {
    min-height: calc(100vw * 3 / 4);
  }

  h1 {
    margin: 0.75em 0;

    @media ${(props) => props.theme.xxxlargeDown} {
      margin: 0.5em 0;
    }

    @media ${(props) => props.theme.xsmallDown} {
      margin: 0.1em 0 0.4em;
    }
  }
`

const Heading = styled.h1.attrs({ className: `styled-h2` })`
  > .-pre {
    display: block;
    opacity: 0.6;
  }
`

const Description = styled.div.attrs({ className: `styled-p` })`
  margin: 0.5em 0 1em;
  opacity: 0.8;
`

const RowStyled = styled(Row)`
  min-height: 100%;
  align-items: flex-end;
`

const CellStyled = styled(Cell)`
  padding-right: 3em;
  background-color: ${(props) => props.color || props.theme.colorPurple};

  @media ${(props) => props.theme.xsmallDown} {
    padding-right: 1em;
  }
`

const HeroColor = ({
  data: { title, pretitle, description, backgroundColor },
}) => {
  return (
    <Container color={backgroundColor}>
      <Grid dots={{ color: `#fff`, opacity: 0.2 }} fillHeight>
        <RowStyled>
          <CellStyled maxSize={20} color={backgroundColor}>
            <Heading>
              {pretitle && (
                <span
                  className="-pre"
                  dangerouslySetInnerHTML={{ __html: pretitle }}
                />
              )}

              <span dangerouslySetInnerHTML={{ __html: title }} />
            </Heading>

            {description && (
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            )}
          </CellStyled>
        </RowStyled>
      </Grid>
    </Container>
  )
}

HeroColor.propTypes = {
  data: PropTypes.object.isRequired,
}

export default HeroColor
