import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"
import Img from "gatsby-image/withIEPolyfill"

import Grid from "../dotgrid/grid"
import Row from "../dotgrid/row"
import Cell from "../dotgrid/cell"
import HeroBanner from "./hero-banner"

const Container = styled.div`
  position: relative;
  overflow: hidden;
`

const Bg = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  overflow: hidden;
  /* background-color: ${(props) => props.theme.colorPurple}; */
`

const Caption = styled.div`
  margin-bottom: ${rem(40)};
  margin-top: ${rem(40)};
  max-width: 50%;
  color: ${(props) => props.theme.colorWhite};

  @media ${(props) => props.theme.xxlargeDown} {
    display: none;
  }

  p:not(:first-child) {
    opacity: 0.6;
  }
`

const Inner = styled.div`
  height: 0; /* IE */
  min-height: calc(100vh - 320px);
  display: flex;
  flex-direction: column;

  @media ${(props) => props.theme.xlargeDown} {
    min-height: 65vh;
  }

  @media ${(props) => props.theme.mediumDown} {
    min-height: 100vw;
  }

  @media ${(props) => props.theme.smallDown} {
    min-height: calc(100vw * 5 / 4);
  }
`

const Dots = styled.div`
  min-height: ${rem(100)};
  flex: 1;
  display: flex;
`

const Intro = styled.div`
  @media ${(props) => props.theme.xxlargeUp} {
    display: none;
  }

  .-content > * {
    &:not(:first-child) {
      color: ${(props) => props.theme.colorPurpleLight};
    }
  }
`

const HeroLanding = ({
  data: {
    title,
    description,
    backgroundImage,
    backgroundImageHalign,
    backgroundImageValign,
    banner,
  },
}) => {
  let singleBackgroundImage
  if (Array.isArray(backgroundImage)) {
    singleBackgroundImage =
      backgroundImage[
        Math.floor(Math.random() * Math.floor(backgroundImage.length))
      ]
  } else {
    singleBackgroundImage = backgroundImage
  }

  return (
    <React.Fragment>
      <Container>
        <Grid noTopPadding noBottomPadding>
          <Row>
            {description && (
              <Caption
                className="styled-h4"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
          </Row>
        </Grid>

        <Inner>
          <Dots>
            <Grid dots={{ color: `#fff`, opacity: 0.4 }} fillHeight />
          </Dots>

          {banner && <HeroBanner data={banner} />}
        </Inner>

        <Bg>
          <h1
            className="is-vhidden"
            dangerouslySetInnerHTML={{ __html: title }}
          />

          {singleBackgroundImage && singleBackgroundImage.localFile && (
            <Img
              fluid={singleBackgroundImage.localFile.childImageSharp.fluid}
              objectPosition={`
                ${backgroundImageHalign || `center`}
                ${backgroundImageValign || `center`}
              `}
              objectFit="cover"
              style={{
                width: `100%`,
                height: `100%`,
                display: `block`,
              }}
              alt={title}
            />
          )}
        </Bg>
      </Container>

      <Intro>
        <Grid>
          <Row pullY={3}>
            <Cell
              size={12}
              mq={{ xsmallDown: { size: 23 } }}
              className="styled-p-leading -content"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </Row>
        </Grid>
      </Intro>
    </React.Fragment>
  )
}

HeroLanding.propTypes = {
  data: PropTypes.object.isRequired,
}

export default HeroLanding
